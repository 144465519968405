<template>
    <div class="hero-area">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="heading">
                        <h1 class="text-white wow fadeInUp" data-wow-delay=".2s">
                            We Are <br /> Coming Soon
                        </h1>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-7">
                    <div class="wow fadeInRight" data-wow-delay=".4s" data-countdown="2025/02/01"></div>
                </div>
                <div class="col-xl-5 col-lg-5">
                    <p class="wow fadeInLeft" data-wow-delay=".4s">
                        We're strong believers that the best solutions come from gathering new insights and pushing
                        conventional boundaries. Stay tuned for updates as we prepare to launch many innovative software
                        solutions.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HeroArea"
};
</script>

<style scoped>
/* Add any specific styles here */
</style>